<template>
	<main
		class="order-page container-white"
		:class="{'mobile-container': mobileCheck()}"
	>
		<section class="container-base order-page__container">
			<div class="order-page__breadcrumbs">
				<Breadcrumbs :linkArr="this.linkArr"/>
			</div>
			<Order v-if="$store.state.address.restarauntId"/>
		</section>
		<BottomNavigation/>
	</main>
</template>
<script>
import Updater from '@/mixins/updater.js'
import {useYandexMetrika} from "@/composables";
import {mobileCheck} from "@/helpers";
import BottomNavigation from "@/components/nav/BottomNavigation";

export default {
	setup()
	{
		const {yandexMetrika} = useYandexMetrika();

		return {yandexMetrika};
	},
	components: {
		BottomNavigation,
		Breadcrumbs: () => import('@/components/block/Breadcrumbs.old.vue'),
		Order: () => import('@/components/layout/Order.vue'),
	},
	methods: {
		mobileCheck
	},
	mixins: [Updater],
	async mounted()
	{
		await this.yandexMetrika();
	},
	data()
	{
		return {
			linkArr:
				[
					{
						name: "Оформление",
						link: "/order"
					},
				]
		}
	}
}
</script>
<style lang="scss">
.order-page
{padding: 24px 0 44px 0;}

.order-page__container
{
	max-width: 928px;
	padding-bottom: 74px;
}

.order-page__cart
{
	width: 592px;
	padding-left: 25px;
	padding-top: 29px;
}

.order-page__search
{
	margin-bottom: 20px;
}

.order-page__breadcrumbs
{
	margin-top: 0;
	margin-bottom: 26px;
}

.container
{
	width: 100%;
	max-width: 1545px;
	padding-left: 15px;
	padding-right: 15px;
	margin: 0 auto;
}

@media (max-width: 767px)
{
	.order-page__breadcrumbs
	{
		display: none;
	}
}
</style>
